// TEST SCRIPT
console.log("script working");

if (process.env.NODE_ENV !== "production") {
  console.log("Looks like we are in development mode");
}

// CONTENT MENU DISABLE/ENABLE [COPY/PAST]
const content = document.querySelector(".content");
if (content) {
  content.addEventListener("contextmenu", (e) => e.preventDefault());
}

// IMPORTS:

// Stylesheet
import "../styles/css/style.css";

// Assets:

// Banners
import "../assets/images/banners/banner-showcase.jpg";
import "../assets/images/banners/clawscc-banner.png";
// Images
import "../assets/images/features/328257521_699156795275306_2272535842138527662_n.jpg";
import "../assets/images/features/106912370_138574294531851_1653592579813217726_n.jpg";
import "../assets/images/features/420493584_816949286904098_2122506795808299108_n.jpg";
import "../assets/images/features/98453747_111587437230537_7996425040029548544_n.jpg";

// Documents
import "../docs/.htaccess";
import "../docs/sitemap.xml";

// Portal Documents
// import "../docs/s-ep-empower-pro.pdf";
// import "../docs/s-ep-scope-of-work.pdf";

